<template>
  <div class="hold-all">

    <router-view />
  </div>
</template>

<style lang="scss">
.hold-all {
  background-color: #000;
  min-height: 100vh;
  overflow: hidden;
  padding-bottom: 80px;
}
</style>
