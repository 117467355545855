import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import BootstrapVue3 from 'bootstrap-vue-3'

// Optional: include BootstrapVue CSS files
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const globalOptions = {
  mode: 'auto',
};


createApp(App).use(store).use(BootstrapVue3).use(VueTelInput, globalOptions).use(VueSweetalert2).use(router).mount("#app");
import "./assets/scss/main.scss"
